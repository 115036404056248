import { EditorSDK, PageRef } from '@wix/platform-editor-sdk';
import { log, toMonitored } from '../../utils/monitoring';
import * as pagesWrapper from '../wrappers/pages';
import * as componentsWrapper from '../wrappers/components';
import { APP_TOKEN } from '../constants';
import { registerAlwaysAvailableApps } from '../services/integration';
import * as pagesService from '../services/pages';

const refreshManifest = async (editorSDK: EditorSDK) => {
  const applicationPages = await editorSDK.document.pages.getApplicationPages(APP_TOKEN);
  if (applicationPages.length === 1) {
    await editorSDK.document.application.reloadManifest();
  }
};

const removeMembersAreaPage = async (options: { editorSDK: EditorSDK; id: string }) => {
  await toMonitored('removePage.platform', () => removeMembersAreaPagePlatform(options));
  await registerAlwaysAvailableApps(options.editorSDK);
  await refreshManifest(options.editorSDK);
};

const removeMembersAreaPageByPageId = async ({
  pageId,
  appDefinitionId,
  editorSDK,
}: {
  pageId: string;
  appDefinitionId: string;
  editorSDK: EditorSDK;
}) => {
  const page = await pagesService.getPageByIntegrationApp({ editorSDK, app: { pageId, appDefinitionId } });
  return removeMembersAreaPage({
    editorSDK,
    id: page?.id!,
  });
};

const getPageToNavigateAfterRemove = async (editorSDK: EditorSDK, { id }: { id: string }) => {
  const allPages = await pagesWrapper.getAllPages({ editorSDK });
  const currentPageRef = await pagesWrapper.getCurrentPage({ editorSDK });
  let navigateTo: PageRef | { id: string } | undefined = currentPageRef;
  const pageToDelete = allPages.find((p) => p.id === id);
  if (currentPageRef.id === pageToDelete?.id) {
    navigateTo = await pagesWrapper.getMainMembersPageRef({ editorSDK });
  }
  return navigateTo;
};

const removeMembersAreaPagePlatform = async ({ editorSDK, id }: { editorSDK: EditorSDK; id: string }) => {
  const componentRef = await componentsWrapper.getById({ editorSDK, id });
  if (!componentRef) {
    log('removeMembersAreaPagePlatform - componentRef not found');
    return;
  }

  const pageRef = await editorSDK.components.getPage(APP_TOKEN, { componentRef });
  if (!pageRef) {
    log('removeMembersAreaPagePlatform - pageRef not found');
    return;
  }

  return pagesWrapper.remove({
    editorSDK,
    pageRef,
    shouldShowEditorRemovePanel: false,
    pageToNavigateAfterRemove: await getPageToNavigateAfterRemove(editorSDK, { id }).catch((e) => {
      log('getPageToNavigateAfterRemove failed in removeMembersAreaPagePlatform', { extra: e });
      return undefined;
    }),
  });
};

export { removeMembersAreaPage, removeMembersAreaPageByPageId };
